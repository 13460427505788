import { GatsbyBrowser, navigate } from 'gatsby';

import './src/theme/tailwind.scss';

export { onRouteUpdate } from '@simpel-web-mono/shared-util/utils';

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] =
  () => {
    const hash = window.location.hash;

    if (hash) {
      setTimeout(() => navigate(hash), 25); // just to be safe
    }
  };
